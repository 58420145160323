<template>
  <div class="text-center mx-auto">
    <svg
      class="mx-auto"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
      width="32"
      height="32"
      style="
        shape-rendering: auto;
        display: block;
        background: rgb(255, 255, 255);
      "
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <g>
        <circle
          stroke-linecap="round"
          fill="none"
          stroke-dasharray="50.26548245743669 50.26548245743669"
          stroke="#f3973e"
          stroke-width="8"
          r="32"
          cy="50"
          cx="50"
        >
          <animateTransform
            values="0 50 50;360 50 50"
            keyTimes="0;1"
            dur="1s"
            repeatCount="indefinite"
            type="rotate"
            attributeName="transform"
          ></animateTransform>
        </circle>
        <g></g>
      </g>
    </svg>
  </div>
</template>
