import { createApp } from "vue";
import App from "./App.vue";

const app = createApp(App);
app.config.productionTip = false;

import router from "./router";
app.use(router);

import LoadingFull from "./components/LoadingFull.vue";
app.component("LoadingFull", LoadingFull);

import SearchingSvg from "./components/SearchingSvg.vue";
app.component("SearchingSvg", SearchingSvg);

// Axios
import axios from "axios";
import VueAxios from "vue-axios";
app.use(VueAxios, axios);

//Notificaciones
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
app.use(VueSweetalert2, { position: "top-end", timer: 5000, toast: true });

//Configuración para formato moneda
app.config.globalProperties.filters = {
  formatMoney(value) {
    return new Intl.NumberFormat("es-CO", {
      style: "currency",
      currency: "COP",
      maximumSignificantDigits: 21,
    }).format(value);
  },
};

app.mount("#app");
