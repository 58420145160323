import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/HomeView.vue"),
    redirect: "/dashboard",
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("../views/DashboardView.vue"),
        meta: {
          title: "Inicio | TaQui Wallet",
        },
      },
      {
        path: "/reserva/:idReserva",
        name: "reserva",
        component: () => import("../views/ReservaView.vue"),
        meta: {
          title: "Reserva | TaQui Wallet",
        },
      },
      {
        path: "/enviarEntrada",
        name: "EnviarEntrada",
        component: () => import("../views/EnviarEntradaView.vue"),
        meta: {
          title: "Enviar entrada | TaQui Wallet",
        },
      },
      {
        path: "/transferencia/:idEntradaTransferida",
        name: "transferencia",
        component: () => import("../views/TransferenciaView.vue"),
        meta: {
          title: "Transferencia | TaQui Wallet",
        },
      },
      {
        path: "/envios",
        name: "envios",
        component: () => import("../views/EnviosView.vue"),
        meta: {
          title: "Transferencias | TaQui Wallet",
        },
      },
      {
        path: "/historial",
        name: "historial",
        component: () => import("../views/HistorialView.vue"),
        meta: {
          title: "Historial | TaQui Wallet",
        },
      },
      {
        path: "/perfil",
        name: "perfil",
        component: () => import("../views/PerfilView.vue"),
        meta: {
          title: "Perfil | TaQui Wallet",
        },
      },
    ],
  },
  {
    path: "/auth",
    name: "Auth",
    component: () => import("../views/AuthView.vue"),
    meta: {
      title: "Inicia sesión | TaQui Wallet",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to) => {
  document.title = to.meta?.title ?? " TaQui Wallet";
});

export default router;
